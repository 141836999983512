<template>
  <div class="ml-2 mr-2">

    <b-row class="mt-12">
      <b-col cols="12">

        <b-form-group style="margin-left: 8px;" label-cols-sm="12" content-cols-sm="10" label-cols-md="2"
          content-cols-md="4" :label="$t('MUCF.SEARCH_COMPANY')">
          <b-form-input
            v-model="search_text"
          />
        </b-form-group>

      </b-col>

    </b-row>


    <b-row class="mt-8">
      <b-col cols="6">
        <div class="bottom-alignment">
          <strong style="margin-left: 8px;">{{ $t('MUCF.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 250]"
            v-model="per_page"
          />
          <b-pagination class="mb-0 mt-2 align-bottom" v-model="current_page" :total-rows="total_rows"
            :per-page="per_page" aria-controls="member-table" first-number last-number></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="mucf-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"

      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="mucfTable"
      @filtered="onFiltered"
      @sort-changed="onSortChanged">

      <template #head(selected)="row">
        <span></span>
      </template>
      <template #cell(selected)="row">

        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
          <input type="checkbox" name="" v-model="row.item.selected" @change="mucf_include_checked(row.item)" />
          <span></span>
        </label>

      </template>
      <template #cell(actions)="row">
        <div class="justify-content-end d-flex">
          <!--
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_company_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>

          <a
            href="#"
            class="btn btn-icon btn-light btn-sm"
            @click.prevent="delete_company_clicked(row.item.id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>-->

        </div>
      </template>

    </b-table>

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import isBetween from 'dayjs/plugin/isBetween';
import { is_mobile } from '@/core/services/utils';

dayjs.extend(isBetween);

export default {
  name: 'MucfSettings',
  mixins: [ toasts ],
  components: {

  },
  computed: {
    ...mapGetters(['currentPeriodId', 'periods', 'companies']),
    currentPeriod() {
      return this.periods.find(period => period.id === this.currentPeriodId);
    },

  },
  async mounted() {

    this.setup_company_rows();

    this.fields = this.fields.filter(item => item.visible);
  },
  watch: {
    search_text() {
      this.items = this.copy_rows.filter(item => item.name.toUpperCase().indexOf(this.search_text.toUpperCase()) >= 0)
    },
    all_selected() {
      for (const c of this.copy_rows) {
        c.selected = this.all_selected;
      }
    },

    async currentPeriodId() {
      this.setup_company_rows();
    }
  },
  methods: {

    async mucf_include_checked(item) {
      console.log('mucf_include_checked', item);

      if (item.company_mucf_id) {
        await this.put_company_mucf(item.company_mucf_id, item.selected, 'DRAFT');
      }
      else {
        const company_mucf = await this.post_company_mucf(item.company_id, this.currentPeriodId, item.selected);

        const company = this.items.find(i => i.company_id === item.company_id);
        company.company_mucf_id = company_mucf.id;
        company.selected = item.selected;

        this.items = [ ...this.items ];
        this.copy_rows = [ ...this.items ];
      }
    },

    async setup_company_rows() {
      try {

        this.items = [];

        const mucfs = await this.load_company_mucfs();

        for (const company of this.companies) {
          const mucf = mucfs.find(item => item.company_id === company.company_id);

          const new_company = { ...company };

          new_company.selected = false;

          this.items.push(new_company);

          if (!mucf) {
            console.error('mucf not found for company id');
            new_company.company_mucf_id = null;
            continue;
          }

          new_company.company_mucf_id = mucf.id;

          if (mucf.included) {
            new_company.selected = true;
          }
        }
      }
      catch (err) {
        console.error('setup_company_rows error', err)
      }

      this.copy_rows = [ ...this.items ]

      this.total_rows = this.copy_rows.length;
    },

    async load_company_mucfs() {
      try {
        const res = await axios.get(`/company_mucf/period/${this.currentPeriodId}`);

        if (res.status === 200) {
          return res.data;
        }

      }
      catch (err) {
        console.error('load_company_mucfs error', err);
      }

      return []
    },

    company_deleted(company_id) {

    },

    select_company_clicked(id) {

    },

    delete_company_clicked(id) {

    },


    async put_company_mucf(id, included, status) {
      try {
        const res = await axios.put(`/company_mucf/${id}`, {
          included,
          status
        });

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('put_company_mucf error', err);
      }

      throw `unable to update company_mucf`;
    },

    async post_company_mucf(company_id, period_id, included) {
      try {
        const res = await axios.post(`/company_mucf`, {
          company_id,
          period_id,
          included,
          status: 'DRAFT'
        });

        if (res.status === 201) {
          return res.data;
        }
      }
      catch (err) {
        console.error('post_company_mucf error', err);
      }

      throw `unable to create company_mucf`;
    },

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },


    get_options() {

      const options = {};

      return options;
    },

    onRowSelected(items) {
      this.selected = items;
    },

    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.allSelected = false;
    },


  },
  data() {
    return {

      items: [],

      search_text: '',
      total_rows: 0,
      orderBy: { created_at: 'desc' },
      allSelected: false,
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      all_selected: null,
      selected: [],
      fields: [

        {
          visible: true,
          key: 'selected',
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },

        {
          visible: true,
          key: 'cnum',
          label: this.$t('COMPANY.CNUM'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'name',
          label: this.$t('COMPANY.NAME'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: true,
          key: 'actions',
          label: '',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>



:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
}

:deep .td-short4 {
  width: 96px;
}

:deep .td-short5 {
  width: 116px;
}


:deep td {
  vertical-align: middle !important;
}

:deep .form-group {
  margin-bottom: 2px;
}


@media screen and (max-width: 768px) {
  #multiple-members-btn {
    margin-bottom: 2rem;
  }

  .date-time-picker {
    margin: 0;
  }

  .filter-box {
    margin-top: 1rem;
    padding: 0 !important;
  }
}

#status-filter {
  width: 200px;
}

.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}
</style>
