<template>
  <div id="page-mucf">
    <h5 class="m-4">MUCF</h5>

    <v-tabs background-color="white" color="accent-4" left>
      <v-tab @click="clicked_mucf_guide">{{ $t('MUCF.MUCF_GUIDE') }}</v-tab>
      <v-tab>{{ $t('MUCF.SETTINGS') }}</v-tab>
    
      <v-tab-item>
        <MucfWizard 
          ref="mucf-wizard"
        />
      </v-tab-item>

      <v-tab-item>
        <MucfSettings />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MucfWizard from './mucf/MucfWizard.vue';
import MucfSettings from './mucf/MucfSettings.vue';

export default {
  name: 'MucfPage',
  components: {
    MucfWizard,
    MucfSettings
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    
  },
  data() {
    return {

    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (newValue) {
        
      }
    }
  },
  methods: {
    clicked_mucf_guide() {
      this.$nextTick(()=>{
        this.$refs['mucf-wizard'].refresh();
      });
    }
  }
};
</script>
